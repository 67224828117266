import React, {Component} from 'react';
import './App.css';
import LoginForm from "./components/loginForm"
import Dashboard from "./components/dashboard"

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      /* 
        mode
          login 
          dashboard
      */
      mode:"login",
      margin_top: "20%",
    }
  }

  tryLogin = async(_id, _ps) => {
    const axios = require('axios');
    axios({
        method:'post',
        url:'https://fn-iot-centralkr-cbf-prod.azurewebsites.net/api/login',
        data:{
          id:_id,
          password:_ps
        }
      }).then(function(response){
        if (response.status === 200){
          this.setState({mode:"dashboard"})
          window.sessionStorage.loggedIn="loggedIn"
        } 
      }.bind(this)).catch(function(error){
        window.sessionStorage.loggedIn="loggedOut"
        alert("로그인에 실패했습니다.")
      })
  }

  getForm(){
    if (window.sessionStorage.loggedIn === "loggedIn"){
      return this.dashboard()
    } else if (this.state.mode === "login"){
      return this.login()
    } else if (this.state.mode === "dashboard"){
      return this.dashboard()
    }
  }

  login(){
    var loginform = <LoginForm 
      margin_top = {this.state.margin_top}
      onClick={
        function(_id, _ps, e){
          this.tryLogin(_id, _ps)
        }.bind(this)
      }
    />
    return loginform
  }

  dashboard(){
    var dashboard = <Dashboard />
    return dashboard
  }
        
  render(){
    return (
      <div className="App">
        {this.getForm()}
      </div>
    );
  }
}

export default App;
