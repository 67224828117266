import React, {Component} from 'react';
import './loginForm.css';
import { TextField } from "../../node_modules/@material-ui/core"
import loginButton from "../resources/button_login.svg"
import background from "../resources/background.png"
import logo from "../resources/logo.png"


class LoginForm extends Component {
  constructor(props){
    super(props)
    this.state={
      id: "",
      ps: "",
    }
  }

  fullyInputed(){
    return (
      this.state.id && 
      this.state.ps
    )
  }

  drawComponenets(){
    var id = <div id="id" className="inputFormLine">
        <TextField label="id" required width='500px' fullWidth={true}
          onChange={
            function(e){
              this.setState({
                id: e.target.value
              })
            }.bind(this)
          }
        />
        </div>

    var ps = <div id="ps" className="inputFormLine">
        <TextField label="password" required width='500px' fullWidth={true}
          type="password"
          onKeyDown={
            function(e){
              if (e.key === "Enter"){
                this.props.onClick(
                  this.state.id,
                  this.state.ps
                )
              }
            }.bind(this)
          }
          onChange={
            function(e){
              this.setState({
                ps: e.target.value
              })
            }.bind(this)
          }
        />
        </div>

    var button = <div id="loginButton">
      <input id="loginButtonElement" type="image" name="button" src={loginButton} alt="버튼"
        onClick={
          function(e){
            this.props.onClick(
              this.state.id,
              this.state.ps
            )
          }.bind(this)
        }>
      </input>
    </div>


    if (!this.state.id){
      return (
        <div>
          {id}
        </div>
      )
    } else if (!this.state.ps){
      return (
        <div>
          {id}
          {ps}
        </div>
      )
    } else {
      return (
        <div>
          {id}
          {ps}
          {button}
        </div>
      )
    } 
  }

  render() {
    return (
      <div id="grid">
        <div id="background">
          <img id="background_image" src={background} alt="background here" />
        </div>
        <div id="active" style={{marginTop: this.props.margin_top}}>
          <img id="logo" src={logo} alt="logo here" margin-top={this.props.margin_top}></img>
          <div id='form'>
            <div id="userIdFormComponenet">
              {this.drawComponenets()}
            </div>
          </div>
          <div id="sangsangfarm" className="cls-7">Imagination Garden Inc.</div>
        </div>
      </div>
    );
  }
}
  
export default LoginForm;