import React, {Component} from 'react';
import './dashboard.css';
import { TextField } from "../../node_modules/@material-ui/core"
import InputAdornment from '../../node_modules/@material-ui/core/InputAdornment';
import background from "../resources/background.png"
import logo from "../resources/logo.png"
import enterButton from "../resources/enterButton.svg"
import device_yangek_on from "../resources/device_yangek_on.svg"
import device_yangek_off from "../resources/device_yangek_off.svg"
import stop_auto from  "../resources/stop_auto.svg"
import stop_auto_overlay from "../resources/stop_auto_overlay.svg"
import resume_auto  from "../resources/resume_auto.svg"
import resume_auto_overlay  from "../resources/resume_auto_overlay.svg"
import device_led_on from "../resources/device_led_on.svg"
import device_led_off from "../resources/device_led_off.svg"
import blocking from "../resources/blocking.png"
import gupsuStart from "../resources/gupsuStart.svg"
import gupsuStart_overlay from "../resources/gupsuStart_overlay.svg"
import gupsuStop from "../resources/gupsu_stop.svg"
import pyesuStart from "../resources/pyesu_start.svg"
import pyesuStart_overlay from "../resources/pyesu_start_overlay.svg"
import pyesuStop from "../resources/pyesu_stop.svg"
import pyesuStop_overlay from "../resources/pyesu_stop_overlay.svg"
import led1Down from "../resources/led1_down.svg"
import led1Up from "../resources/led1_up.svg"
import led1Down_overlay from "../resources/led1_down_overay.svg"
import led1Up_overlay from "../resources/led1_up_overay.svg"
import led1Stop from "../resources/led1_stop.svg"
import led2Down from "../resources/led2_down.svg"
import led2Up from "../resources/led2_up.svg"
import led2Down_overlay from "../resources/led2_down_overlay.svg"
import led2Up_overlay from "../resources/led2_up_overlay.svg"
import led2Stop from "../resources/led2_stop.svg"
import squarelogo from "../resources/squarelogo.png"


const axios = require('axios');

function getDeviceStatus(deviceName){
  return axios.get('https://fn-iot-centralkr-cbf-prod.azurewebsites.net/api/device/' + deviceName)
}

function postDeviceControl(deviceName, requestBody){
  return axios({
    method:'post',
    url:'https://fn-iot-centralkr-cbf-prod.azurewebsites.net/api/device/' + deviceName,
    data:requestBody
  })
}

class Dashboard extends Component {
  constructor(props){
    super(props)
    this.state={
      /*
        mode
          acceptance
          dashboard
      */
      mode:"acceptance",
      deviceId : [
        'LED100-CBF-001',
        'NAB100-CBF-001'
      ],
      ledStatus:null,
      ledConnectionState:null,
      nutrientConnectionState:null,
      nutrientAutomation:true,
      nutrientAutomationMouseOver:false,
      gupSuJung:false,
      pyeSuJung:false,
      gupSuMouseOver:false,
      pyeSuMouseOver:false,
      led1_mode:0,
      led2_mode:0,
      led1DownMouseOver:false,
      led2DownMouseOver:false,
      led1UpMouseOver:false,
      led2UpMouseOver:false,
      previousGupsuTiming: -1,
      previousPyesuTiming: -1,
      previousAutomationTiming: -1,
      timerInterval: 0,
      onOffInterval: 0
    }

    this.nutrientAutomationMouseOverChange = this._nutrientAutomationMouseOverChange.bind(this)
    this.toggleNutrientUssage = this._toggleNutrientUssage.bind(this)
    this.gupSuMouseOverOn = this._gupSuMouseOverOn.bind(this)
    this.gupSuMouseOverOff = this._gupSuMouseOverOff.bind(this)
    this.pyeSuMouseOverChange = this._pyeSuMouseOverChange.bind(this)
    this.toggleGupSu = this._toggleGupSu.bind(this)
    this.togglePyeSu = this._togglePyeSu.bind(this)
    this.stopLed1 = this._stopLed1.bind(this)
    this.stopLed2 = this._stopLed2.bind(this)
    this.toggleLed1UpMouseOver = this._toggleLed1UpMouseOver.bind(this)
    this.toggleLed1DownMouseOver = this._toggleLed1DownMouseOver.bind(this)
    this.toggleLed2UpMouseOver = this._toggleLed2UpMouseOver.bind(this)
    this.toggleLed2DownMouseOver = this._toggleLed2DownMouseOver.bind(this)
    this.led1UpPress = this._led1UpPress.bind(this)
    this.led1DownPress = this._led1DownPress.bind(this)
    this.led2UpPress = this._led2UpPress.bind(this)
    this.led2DownPress = this._led2DownPress.bind(this)
  }

  _gupSuMouseOverOn(){
    this.setState({
      gupSuMouseOver: true
    })
  }
  _gupSuMouseOverOff(){
    this.setState({
      gupSuMouseOver: false
    })
  }

  _toggleNutrientUssage(){
    var current = new Date()
    var seconds = current - this.state.previousAutomationTiming
    if (this.state.previousAutomationTiming < 0 || seconds > 5000){
      if (!this.state.nutrientAutomation || (this.state.nutrientAutomation && window.confirm("정말 자동화를 중단하시겠습니까?"))){
        this.controlAutomation(!this.state.nutrientAutomation)
        this.setState({
          nutrientAutomation: !this.state.nutrientAutomation,
          previousAutomationTiming: current
        })
      }
    } else {
      alert("회로 수명보호를 위하여 " + parseInt(6 - seconds/1000) + "초 동안 입력이 제한됩니다.")
    }
  }

  _toggleGupSu(){
    var current = new Date()
    var seconds = current - this.state.previousGupsuTiming
    if (this.state.previousGupsuTiming < 0 || seconds > 5000){
      if (this.state.gupSuJung || (!this.state.gupSuJung && window.confirm("정말 급수를 실시하시겠습니까?"))){
        this.gupSuControl(!this.state.gupSuJung)
        this.setState({
          gupSuJung: !this.state.gupSuJung,
          previousGupsuTiming:current
        })
      }
    } else {
      alert("회로 수명보호를 위하여 " + parseInt(6 - seconds/1000) + "초 동안 입력이 제한됩니다.")
    }
  }

  _togglePyeSu(){
    var current = new Date()
    var seconds = current - this.state.previousPyesuTiming
    if (this.state.previousPyesuTiming < 0 ||  seconds> 5000){
      if (this.state.pyeSuJung || (!this.state.pyeSuJung && window.confirm("정말 폐수를 실시하시겠습니까?"))){
        this.pyeSuControl(!this.state.pyeSuJung)
        this.setState({
          pyeSuJung: !this.state.pyeSuJung,
          previousPyesuTiming:current
        })
      }
    } else{
      alert("회로 수명보호를 위하여 " + parseInt(6 - seconds/1000) + "초 동안 입력이 제한됩니다.")
    }
  }

  // LED2번 제어 방향 수정
  _led2DownPress(){
    if (window.confirm("정말 2번 LED를 내리시겠습니까?")){
      this.setState({
        led2_mode: 1,
        led2DownMouseOver:false
      })
      this.controlLED2(1)
    }
  }

  // LED2번 제어 방향 수정
  _led2UpPress(){
    if (window.confirm("정말 1번 LED를 올리시겠습니까?")){
      this.setState({
        led2_mode: 2,
        led2UpMouseOver:false
      })
      this.controlLED2(2)
    }
  }
  // LED1번 제어 방향 변경
  _led1DownPress(){
    if (window.confirm("정말 1번 LED를 내리시겠습니까?")){
      this.setState({
        led1_mode: 1,
        led1DownMouseOver:false
      })
      this.controlLED1(1)
    }
  }
  //LED1번 제어 방향 변경
  _led1UpPress(){
    if (window.confirm("정말 1번 LED를 올리시겠습니까?")){
      this.setState({
        led1_mode: 2,
        led1UpMouseOver:false
      })
      this.controlLED1(2)
    }
  }

  _toggleLed2DownMouseOver(){
    this.setState({
      led2DownMouseOver: !this.state.led2DownMouseOver
    })
  }

  _toggleLed1DownMouseOver(){
    this.setState({
      led1DownMouseOver: !this.state.led1DownMouseOver
    })
  }

  _toggleLed2UpMouseOver(){
    this.setState({
      led2UpMouseOver: !this.state.led2UpMouseOver
    })
  }

  _toggleLed1UpMouseOver(){
    this.setState({
      led1UpMouseOver: !this.state.led1UpMouseOver
    })
  }

  _stopLed1(){
    this.setState({
      led1_mode:0
    })
    this.controlLED1(0)
  }

  _stopLed2(){
    this.setState({
      led2_mode:0
    })
    this.controlLED2(0)
  }

  controlTimerInterval = async() => {
    var requestBody = {
      property:{
        outlet:{
          0:{
            timerInterval: Number(this.state.timerInterval),
            onOffInterval: Number(this.state.onOffInterval)
          }
        }
      }
    }

    var _timerIntervalStatus = null
    var _onOffIntervalStatus = null
    var _nutrientConnectionState = null

    var controlPost = postDeviceControl(this.state.deviceId[1], requestBody)
    controlPost.then(function(response){
      _timerIntervalStatus = response.data.properties.desired.outlet.["0"].timerInterval
      _onOffIntervalStatus = response.data.properties.desired.outlet.["0"].onOffInterval
      _nutrientConnectionState = response.data.connectionState === "Connected"? true: false
      if (this.state.timerInterval !== _timerIntervalStatus ||
        this.state.onOffInterval !== _onOffIntervalStatus ||
        this.state.nutrientConnectionState !== _nutrientConnectionState){
          this.setState({
            timerInterval:_timerIntervalStatus,
            onOffInterval:_onOffIntervalStatus,
            nutrientConnectionState:_nutrientConnectionState
           })
      }
    }.bind(this)).catch(function(error){
      this.setState({nutrientConnectionState: false})
    }.bind(this))
    alert("관수 주기가 변경되었습니다.")
  }
  
  controlLED2 = async(control) => {
    var requestBody = {
      property:{
        motors:{
          1:{
            state:control
          },
          0:{
            state:this.state.led1_mode
          }
        }
      }
    }

    var _led2_mode = null
    var _ledConnectionState = null

    var controlPost = postDeviceControl(this.state.deviceId[0], requestBody)
    controlPost.then(function(response){
      _led2_mode = response.data.properties.desired.motors.["1"].state
      _ledConnectionState= response.data.connectionState === "Connected"? true: false
      if (control !== _led2_mode ||
        this.state.ledConnectionState !== _ledConnectionState){
          this.setState({
            led2_mode:_led2_mode,
            ledConnectionState:_ledConnectionState,
           })
      }
    }.bind(this)).catch(function(error){
      this.setState({ledConnectionState: false})
    }.bind(this))
  }

  controlLED1 = async(control) => {
    var requestBody = {
      property:{
        motors:{
          0:{
            state:control
          },
          1:{
            state:this.state.led2_mode
          }
        }
      }
    }
    var _led1_mode = null
    var _ledConnectionState = null
    var controlPost = postDeviceControl(this.state.deviceId[0], requestBody)
    controlPost.then(function(response){
      _led1_mode = response.data.properties.desired.motors.["0"].state
      _ledConnectionState= response.data.connectionState === "Connected"? true: false
      if (control !== _led1_mode ||
        this.state.ledConnectionState !== _ledConnectionState){
          this.setState({
            led1_mode:_led1_mode,
            ledConnectionState:_ledConnectionState,
           })
      }
    }.bind(this)).catch(function(error){
      this.setState({ledConnectionState: false})
    }.bind(this))
  }

  controlAutomation = async(control) => {
    var requestBody = {
      property:{
        feedMaker:{
          power:control
        }
      }
    }
    var _automation = null
    var _ledConnectionState = null
    var controlPost = postDeviceControl(this.state.deviceId[1], requestBody)
    controlPost.then(function(response){
      _automation = response.data.properties.desired.feedMaker.power
      _ledConnectionState= response.data.connectionState === "Connected"? true: false
      if (this.state.nutrientAutomation !== _automation ||
        this.state.ledConnectionState !== _ledConnectionState){
          this.setState({
            nutrientAutomation:_automation,
            ledConnectionState:_ledConnectionState,
           })
      }
    }.bind(this)).catch(function(error){
      this.setState({ledConnectionState: false})
    }.bind(this))
  }

  gupSuControl = async(control) => {
    var requestBody = {
      property:{
        outlet:{
          3:{
            power:control
          }
        }
      }
    }
    var _gupSuJung = null
    var _ledConnectionState = null
    var controlPost = postDeviceControl(this.state.deviceId[1], requestBody)
    controlPost.then(function(response){
      _gupSuJung = response.data.properties.desired.outlet.["3"].power
      _ledConnectionState= response.data.connectionState === "Connected"? true: false
      if (this.state.gupSuJung !== _gupSuJung ||
        this.state.ledConnectionState !== _ledConnectionState){
          this.setState({
            gupSuJung:_gupSuJung,
            ledConnectionState:_ledConnectionState
          })
      }
    }.bind(this)).catch(function(error){
      this.setState({ledConnectionState: false})
    }.bind(this))
  }

  pyeSuControl = async(control) => {
    var requestBody = {
      property:{
        outlet:{
          2:{
            power:control
          }
        }
      }
    }
    var _pyeSuJung = null
    var _ledConnectionState = null
    var controlPost = postDeviceControl(this.state.deviceId[1], requestBody)
    controlPost.then(function(response){
      _pyeSuJung = response.data.properties.desired.outlet.["2"].power
      _ledConnectionState= response.data.connectionState === "Connected"? true: false
      if (this.state.pyeSuJung !== _pyeSuJung ||
        this.state.ledConnectionState !== _ledConnectionState){
          this.setState({
            pyeSuJung:_pyeSuJung,
            ledConnectionState:_ledConnectionState
          })
      }
    }.bind(this)).catch(function(error){
      this.setState({ledConnectionState: false})
    }.bind(this))
  }

  getLedInformation = async() => {
    var _led1_mode = 0;
    var _led2_mode = 0;
    var _ledConnectionState = false
    var ledStatusGet = getDeviceStatus(this.state.deviceId[0])

    ledStatusGet.then(function(response){
      _led1_mode = response.data.properties.desired.motors["0"].state
      _led2_mode = response.data.properties.desired.motors["1"].state
      _ledConnectionState= response.data.connectionState === "Connected"? true: false
      if (this.state.led1_mode !== _led1_mode ||
        this.state.led2_mode !== _led2_mode ||
        this.state.ledConnectionState !== _ledConnectionState){
          this.setState({
            led1_mode:_led1_mode,
            led2_mode:_led2_mode,
            ledConnectionState:_ledConnectionState,
           })
      }
    }.bind(this)).catch(function(error){
      this.setState({ledConnectionState: false})
    }.bind(this))
  }

  getNutreintInformation = async() => {
    var _nutrientConnectionState = false
    var _pyeSuJung = false
    var _gupSuJung = false
    var _automation = true
    var _timerInterval = 0
    var _onOffInterval = 0
    var nutrientStatusGet = getDeviceStatus(this.state.deviceId[1])

    nutrientStatusGet.then(function(response){
      _automation = response.data.properties.desired.feedMaker.power
      _timerInterval = response.data.properties.desired.outlet.['0'].timerInterval
      _onOffInterval = response.data.properties.desired.outlet.['0'].onOffInterval
      _pyeSuJung = response.data.properties.desired.outlet.['2'].power
      _gupSuJung = response.data.properties.desired.outlet.['3'].power
      _nutrientConnectionState= response.data.connectionState === "Connected"? true: false
      if (this.state.nutrientAutomation !== _automation ||
        this.state.pyeSuJung !== _pyeSuJung ||
        this.state.gupSuJung !== _gupSuJung ||
        this.state.timerInterval !== _timerInterval ||
        this.state.onOffInterval !== _onOffInterval ||
        this.state.nutrientConnectionState !== _nutrientConnectionState){
          this.setState({
            nutrientAutomation:_automation,
            pyeSuJung: _pyeSuJung,
            gupSuJung: _gupSuJung,
            nutrientConnectionState: _nutrientConnectionState,
            timerInterval: _timerInterval,
            onOffInterval: _onOffInterval
           })
        }
    }.bind(this)).catch(function(error){
      this.setState({nutrientConnectionState : false})
    }.bind(this))
  }

  getDeviceInformation = async() => {
    this.getLedInformation()
    this.getNutreintInformation()
    this.setState({mode:"dashboard"})
  }

  statusBar(){
    return(
      <div id='statusBarf'>
        <img id="smallLogoFile" src={logo} alt="작은 로고"></img>
        <div id="blank"></div>
        <div id="loginStatus">
          춘천바이오산업진흥원
        </div>
      </div>
    )    
  }

  _nutrientAutomationMouseOverChange(){
    this.setState({
      nutrientAutomationMouseOver: !this.state.nutrientAutomationMouseOver
    })
  }


  _pyeSuMouseOverChange(){
    this.setState({
      pyeSuMouseOver: !this.state.pyeSuMouseOver
    })
  }

  
  drawDashboard(){
    var autoButton = <img id="nutrientOnOff"
    src={ this.state.nutrientAutomation?
      this.state.nutrientAutomationMouseOver? stop_auto : stop_auto_overlay :
      this.state.nutrientAutomationMouseOver? resume_auto : resume_auto_overlay
    }
    onMouseOver={this.nutrientAutomationMouseOverChange}
    onMouseLeave={this.nutrientAutomationMouseOverChange}
    onClick={this.toggleNutrientUssage}
    alt="automation Button"
  />

    var blockOver = <img id="blockingMask"
      src={blocking}
      alt="자동화 작동 중 사용금지"
    />

    var gupsuButton = <img id="gupsuButton"
      src={ this.state.gupSuJung?
        gupsuStop :
        !this.state.gupSuMouseOver? gupsuStart : gupsuStart_overlay 
      }
      onMouseOver={this.gupSuMouseOverOn}
      onMouseLeave={this.gupSuMouseOverOff}
      onClick={this.toggleGupSu}
      alt="급수버튼"
    />

    var pyesuButton = <img id="pyesuButton"
      src={ this.state.pyeSuJung?
        this.state.pyeSuMouseOver? pyesuStop_overlay : pyesuStop :
        this.state.pyeSuMouseOver? pyesuStart_overlay : pyesuStart
      }
      onMouseOver={this.pyeSuMouseOverChange}
      onMouseLeave={this.pyeSuMouseOverChange}
      onClick={this.togglePyeSu}
      alt="폐수버튼"
    />

    var gupsuButtonm = <img id="gupsuButton"
      src={ this.state.gupSuJung?
        gupsuStop : gupsuStart
      }
      onClick={this.toggleGupSu}
      alt="급수버튼"
    />

    var pyesuButtonm = <img id="pyesuButton"
      src={ this.state.pyeSuJung?
        pyesuStop_overlay : pyesuStart
      }
      onClick={this.togglePyeSu}
      alt="폐수버튼"
    />

    var gupPyesuPannel = !this.state.nutrientAutomation?
        <div>
          {gupsuButton}
          {pyesuButton}
        </div>
        :
        <div>{blockOver}</div>

    var gupPyesuPannelm = !this.state.nutrientAutomation?
        <div>
          {gupsuButtonm}
          {pyesuButtonm}
        </div>
        :
        <div>{blockOver}</div>
    
    var LedControlPannel1 = this.state.led1_mode === 0 ?
      <div id="led1_updown_grid" className="ledUpDownGrid">
          <div id="led1_down">
            <img id="led1_down_button" className="ledButtonIcon" alt="led1내리기"
              src={this.state.led1DownMouseOver?
                led1Down_overlay:led1Down
              }
              onMouseOver={this.toggleLed1DownMouseOver}
              onMouseLeave={this.toggleLed1DownMouseOver}
              onClick={this.led1DownPress}
            />
          </div>
          <div id="led1_up">
            <img id="led1_up_button" className="ledButtonIcon" alt="led1올리기"
                src={this.state.led1UpMouseOver?
                  led1Up_overlay:led1Up
                }
                onMouseOver={this.toggleLed1UpMouseOver}
                onMouseLeave={this.toggleLed1UpMouseOver}
                onClick={this.led1UpPress}
              />
          </div>
        </div>
      :
      <div id="led1Stop">
        <img id="led1StopButton" className="ledStopButtonIcon" alt="led1중단"
          src={led1Stop}
          onClick={this.stopLed1}
        />
      </div>
    
    var LedControlPannel2 = this.state.led2_mode === 0 ?
      <div id="led2_updown_grid" className="ledUpDownGrid">
          <div id="led2_down">
            <img id="led2_down_button" className="ledButtonIcon" alt="led2내리기"
              src={this.state.led2DownMouseOver?
                led2Down_overlay:led2Down
              }
              onMouseOver={this.toggleLed2DownMouseOver}
              onMouseLeave={this.toggleLed2DownMouseOver}
              onClick={this.led2DownPress}
            />
          </div>
          <div id="led2_up">
            <img id="led2_up_button" className="ledButtonIcon" alt="led2올리기"
                src={this.state.led2UpMouseOver?
                  led2Up_overlay:led2Up
                }
                onMouseOver={this.toggleLed2UpMouseOver}
                onMouseLeave={this.toggleLed2UpMouseOver}
                onClick={this.led2UpPress}
              />
          </div>
        </div>
      :
      <div id="led2Stop">
        <img id="led2StopButton" className="ledStopButtonIcon" alt="led2중단"
          src={led2Stop}
          onClick={this.stopLed2}
        />
      </div>

      var LedControlPannel1m = this.state.led1_mode === 0 ?
      <div id="led1_updown_grid" className="ledUpDownGrid">
          <div id="led1_down">
            <img id="led1_down_button" className="ledButtonIcon" alt="led1내리기"
              src={led1Down}
              onClick={this.led1DownPress}
            />
          </div>
          <div id="led1_up">
            <img id="led1_up_button" className="ledButtonIcon" alt="led1올리기"
                src={led1Up}
                onClick={this.led1UpPress}
              />
          </div>
        </div>
      :
      <div id="led1Stop">
        <img id="led1StopButton" className="ledStopButtonIcon" alt="led1중단"
          src={led1Stop}
          onClick={this.stopLed1}
        />
      </div>

      var LedControlPannel2m = this.state.led2_mode === 0 ?
      <div id="led2_updown_grid" className="ledUpDownGrid">
          <div id="led2_down">
            <img id="led2_down_button" className="ledButtonIcon" alt="led2내리기"
              src={led2Down}
              onClick={this.led2DownPress}
            />
          </div>
          <div id="led2_up">
            <img id="led2_up_button" className="ledButtonIcon" alt="led2올리기"
                src={led2Up}
                onClick={this.led2UpPress}
              />
          </div>
        </div>
      :
      <div id="led2Stop">
        <img id="led2StopButton" className="ledStopButtonIcon" alt="led2중단"
          src={led2Stop}
          onClick={this.stopLed2}
        />
      </div>

      var information = <div id="information">
        <img src={squarelogo} id="squaredlogo" alt="정사각형 로고"
          />
        <div id="instructionarea" className="cls-7">
          <ul>
            <li>자동화 진행 중에는 급폐수 동작이 불가능합니다.</li>
            <li>버튼을 클릭하시면 동작이 수행됩니다.</li>
            <li>조명 높이조절이 끝나면 반드시 중지버튼을 눌러주세요.</li>
          </ul>
        </div>
      </div>

      var information_mobile = <div id="information_mobile">
      <img src={squarelogo} id="squaredlogo" alt="정사각형 로고"
        />
      <div id="instructionarea" className="cls-7">
        <ul>
          <li>자동화 진행 중에는 급폐수 동작이 불가능합니다.</li>
          <li>버튼을 클릭하시면 동작이 수행됩니다.</li>
          <li>조명 높이조절이 끝나면 반드시 중지버튼을 눌러주세요.</li>
        </ul>
      </div>
    </div>
    
    var timerIntervalControl = <div id="timerIntervalControlPannel">
        <div className = "textCenter">
          <h2>급수 주기 설정</h2>
        </div>
        <div className="InputPannel">
          <TextField
            className="timerInterval-number"
            value={this.state.timerInterval}
            label="관수 주기"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="start">초 마다</InputAdornment>,
            }}
            onChange={
              function(e){
                e.preventDefault()
                this.setState({
                  timerInterval: e.target.value
                })
              }.bind(this)
            }
          />

          <TextField
            className="timerInterval-number"
            label="급수 기간"
            type="number"
            value={this.state.onOffInterval}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="start">초 급수</InputAdornment>,
            }}
            onChange={
              function(e){
                e.preventDefault()
                this.setState({
                  onOffInterval: e.target.value
                })
              }.bind(this)
            }
          />
        </div>
        <div className="changeIntervalButton" 
          onClick={
            this.controlTimerInterval
          }
        >
            <div className="buttonText">
              적용
            </div>
        </div>
    </div>

    return(
      <div id='drawDashboardHere'>
        <div id="dashboardGrid">
          
          <div id="leftPannel">
          <div id='mobile'>
              <div id="yangek_control_info_grid">
                <img id="nutrientDeviceInfo" alt="양액제어기 기기상태"
                  src={this.state.nutrientConnectionState ? device_yangek_on : device_yangek_off}
                />
                {autoButton}
              </div>
              <img id="ledDeviceInfo" alt="led제어기 기기상태"
                src={this.state.ledConnectionState ? device_led_on : device_led_off}
              />
              {timerIntervalControl}
              {LedControlPannel1m}
              {LedControlPannel2m}
            
            </div>
            <div id='desktop_ledpannel'>
              {timerIntervalControl}
              {LedControlPannel1}
              {LedControlPannel2}
            </div>
            {information}
          </div>
          <div id="rightPannel">
            <div id='desktop'>
              <div id="yangek_control_info_grid">
                <img id="nutrientDeviceInfo" alt="양액제어기 기기상태"
                  src={this.state.nutrientConnectionState ? device_yangek_on : device_yangek_off}
                />
                {autoButton}
              </div>
              <img id="ledDeviceInfo" alt="led제어기 기기상태"
                src={this.state.ledConnectionState ? device_led_on : device_led_off}
              />
              {gupPyesuPannel}
            </div>
            <div id="mobileGupPyeSu">
              {gupPyesuPannelm}
            </div>
            {information_mobile}
          </div>
        </div>
      </div>
    )
  }

  drawAgreePage(){
    return(
      <div id="grid">
        <div id="background">
          <img id="background_image" src={background} alt="background here" 
            />
        </div>
        <div id="active" >
          <img id="logo" src={logo} alt="logo here" margin-top='30%'></img>
          <div id='form'>
            <div>
              <div>
                IoT스테이션에서 입력한 제어명령은<br/>
                실제 스마트팜에 현실적인 영향을 끼칩니다.<br/>
                부적절한 제어입력은 시설 운영 실패로 이어질 수 있으므로<br/>
                반드시 관련 지식을 보유하신 분께서만 IoT 스테이션에 입장하여 주시기 바랍니다.
              </div>
              <div id="enterButton" >
                <input type="image" name="button" src={enterButton} alt="버튼" 
                  onClick={this.getDeviceInformation}/>
              </div>
            </div>
          </div>
          <div id="sangsangfarm" className="cls-7">Imagination Garden Inc.</div>
        </div>
      </div>
    )
  }

  enterDashboard(){
    if (this.state.mode ==="acceptance"){
      return this.drawAgreePage()
    } else if (this.state.mode === 'dashboard'){
      return (
        <div id="dashboardBoard">
          <div id="qwer">
            {this.statusBar()}
          </div>
          <div id="sdfas">
            {this.drawDashboard()}
          </div>
          <div id="sangsangfarmAtDashboard" className="cls-7">Imagination Garden Inc.</div>
        </div>
      )
    }
  }  

  render() {
    return (
      <div id="dashboardelement">{this.enterDashboard()}</div>
    );
  }
}
  
export default Dashboard;
